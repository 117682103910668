.presales-padding-small {
  padding-bottom: 10px;
}

.presales-hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-presales .section_footer {
  background-color: #1e1e2f !important;
}

.presales-hero .presales-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 470px;
  background-color: #fff;
  padding: 0px;
  padding-bottom: 20px;
  text-align: center;
}

.presales-hero .presales-info .presales-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  background-color: #1e1e2f;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.presales-hero .presales-info .presales-image p{
  padding-top: 12px;
}

.presales-hero .presales-info .presales-action{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}

@media screen and (max-width: 425px) {
  .page-presales .page-padding {
    padding: 0px !important;
  }
}