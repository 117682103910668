.container-search-hot {
  display: none;
  flex-direction: column;
  width: 280px;
  margin-top: 4px;
  padding-top: 15px;
  position: absolute;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #DCDCE3;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 999;
}

.title-search-hot {
  padding: 0 15px;
}

.title {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  color: #6B6978;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
}

.title-icon {
  padding-right: 10px;
}

.title-fire-icon {
  width: 13px;
  height: 13px;
}

.title-close-icon {
  position: absolute;
  margin-right: 15px;
  right: 0;
  cursor: pointer;
}

.divisor {
  height: 1px;
  background-color: #DCDCE3;
}

.item-search-hot {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.item-search-hot:hover {
  background-color: #F0F0F0;
}

.left-search-hot {
  display: flex;
  align-items: center;
}

.left-search-hot img {
  width: 20px;
  height: 20px;
}

.left-search-hot span {
  font-size: 13px;
  color: #6B6978;
  padding-left: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.item-search-hot:hover .left-search-hot span {
  color: #27224E;
}

.right-search-hot span {
  color: #B9B9B9;
}

.item-search-hot:hover .right-search-hot span {
  color: #6B6978;
}